import React, {useState, useEffect} from "react";
import {Container, Form, Icon, Input, Grid, Message, ListItem, List} from "semantic-ui-react";

const PROVIDER_LIST_HEIGHT = 550;

export default function GroupMembershipEditor(props) {
    const [unaffiliatedProviders, setUnaffiliatedProviders] = useState([]);
    const [selectedGroupProviderFilter, setSelectedGroupProviderFilter] = useState("");
    const [filteredGroupProviders, setFilteredGroupProviders] = useState([]);
    const [unaffiliatedProviderFilter, setUnaffiliatedProviderFilter] = useState("");
    const [filteredUnaffiliatedProviders, setFilteredUnaffiliatedProviders] = useState([]);

    useEffect(function updateUnaffiliatedProviders() {
        if (Array.isArray(props.allProviders) && Array.isArray(props.allGroups)) {
            let affiliatedProviders = new Set();
            for (const group of props.allGroups) {
                for (const provider of group.providers) {
                    affiliatedProviders.add(provider.id);
                }
            }
            affiliatedProviders = Array.from(affiliatedProviders);
            setUnaffiliatedProviders(props.allProviders.filter(provider => !affiliatedProviders.includes(provider.id)));
        } else {
            setUnaffiliatedProviders([]);
        }
    }, [JSON.stringify(props.selectedGroupProviders), JSON.stringify(props.allProviders), JSON.stringify(props.allGroups)]);

    useEffect(function updateFilteredUnaffiliatedProviders() {
        if (Array.isArray(unaffiliatedProviders)) {
            if (unaffiliatedProviderFilter !== "") {
                const providers = [];
                for (const provider of unaffiliatedProviders) {
                    if (provider.displayName.toLowerCase().includes(unaffiliatedProviderFilter.toLowerCase()) || provider.id.toLowerCase().includes(unaffiliatedProviderFilter.toLowerCase())) {
                        providers.push(provider);
                    }
                }
                setFilteredUnaffiliatedProviders(providers);
            } else {
                setFilteredUnaffiliatedProviders(unaffiliatedProviders);
            }
        } else {
            setFilteredUnaffiliatedProviders([]);
        }
    }, [unaffiliatedProviders.length, unaffiliatedProviderFilter, JSON.stringify(props.selectedGroupProviders)]);

    useEffect(function updateFilteredSelectedGroupProviders() {
        if (selectedGroupProviderFilter.length > 0) {
            setFilteredGroupProviders(props.selectedGroupProviders.filter(provider =>
                provider.displayName.toLowerCase().includes(selectedGroupProviderFilter.toLowerCase()) ||
                provider.id.toLowerCase().includes(selectedGroupProviderFilter.toLowerCase()))
            );
        } else {
            setFilteredGroupProviders(props.selectedGroupProviders);
        }
    }, [selectedGroupProviderFilter, JSON.stringify(props.selectedGroupProviders), unaffiliatedProviders.length]);

    const addProviderToGroup = provider => {
        props.TVEManagerDataProvider.addProviderToGroup(provider.id, props.selectedGroupId).then(response => {
            props.updateGroups();
            console.log("(GroupMembershipEditor.addProviderToGroup) response: ", response);
            props.toast("Success", `The provider ${provider.id} was successfully added to the group ${props.selectedGroupId}.`, "success");
        }).catch(error => {
            props.toast("Error", `There was an error adding the provider ${provider.id} to the group ${props.selectedGroupId}.`, "error");
            console.error(error);
        });
    };

    const removeProviderFromGroup = provider => {
        props.TVEManagerDataProvider.removeProviderFromGroup(provider.id, props.selectedGroupId).then(response => {
            props.updateGroups();
            console.log("(GroupMembershipEditor.removeProviderFromGroup) response: ", response);
            props.toast("Success", `The provider ${provider.id} was successfully removed from the group ${props.selectedGroupId}.`, "success");
        }).catch(error => {
            props.toast("Error", `There was an error removing the provider ${provider.id} from the group ${props.selectedGroupId}.`, "error");
            console.error(error);
        });
    };

    return (
        <Container>
            <Form>
                {
                    props.selectedGroupId && Array.isArray(props.selectedGroupProviders) ?
                        <Grid width={16}>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label={`Filter Providers in ${props.selectedGroupId}`}
                                            control={Input}
                                            value={selectedGroupProviderFilter}
                                            onChange={(event, {value}) => setSelectedGroupProviderFilter(value)}
                                        />
                                        <Form.Field
                                            label="Filter Unaffiliated Providers"
                                            control={Input}
                                            value={unaffiliatedProviderFilter}
                                            onChange={(event, {value}) => setUnaffiliatedProviderFilter(value)}
                                        />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>Providers in {props.selectedGroupId}</label>
                                    <Grid.Column style={{overflowX: "hidden", overflowY: "auto", maxHeight: PROVIDER_LIST_HEIGHT}}>
                                        <List
                                            divided
                                            relaxed
                                            items={filteredGroupProviders.map(provider =>
                                                <ListItem key={`${provider.id}-currently-selected`} provider={provider}>
                                                    <Icon name="minus" color="red"/>{provider.displayName} ({provider.id})
                                                </ListItem>)
                                            }
                                            onItemClick={
                                                (event, data) => {
                                                    if (props.userCanEdit) {
                                                        removeProviderFromGroup(data.provider);
                                                    }
                                                }
                                            }
                                        />
                                    </Grid.Column>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Field>
                                    <label>Add Providers</label>
                                    <Grid.Column style={{overflowX: "hidden", overflowY: "auto", maxHeight: PROVIDER_LIST_HEIGHT}}>
                                        <List
                                            divided
                                            relaxed
                                            items={filteredUnaffiliatedProviders.map(provider =>
                                                <ListItem key={`${provider.id}-remaining`} provider={provider}>
                                                    <Icon name="plus" color="green"/>{provider.displayName} ({provider.id})
                                                </ListItem>)
                                            }
                                            onItemClick={
                                                (event, data) => {
                                                    if (props.userCanEdit) {
                                                        addProviderToGroup(data.provider);
                                                    }
                                                }
                                            }
                                        />
                                    </Grid.Column>
                                </Form.Field>
                            </Grid.Column>
                        </Grid> : <Message>The Group {props.selectedGroupId} currently has no selected providers.</Message>
                }
            </Form>
        </Container>
    );
};
