import React, {useState, useEffect} from "react";
import {Container, Icon, Message, Tab} from "semantic-ui-react";
import ReactTable from "react-table-v6";

export default function BrandTopNineSubComponentTab(props) {
    const [loading, setLoading] = useState(false);
    const [primaryOrder, setPrimaryOrder] = useState([]);
    const [collidingMVPDs, setCollidingMVPDs] = useState([]);

    useEffect(function initializeTopNine() {
        setLoading(true);
        props.TVEManagerDataProvider.getPrimaryOrder(props.brand).then(response => {
            if (!response.status) {
                setPrimaryOrder(response.map(countryPrimaryOrder => {
                    return {
                        country: countryPrimaryOrder.country,
                        providers: Object.keys(countryPrimaryOrder.providers).map(mvpd => {
                            return {provider: mvpd, position: countryPrimaryOrder.providers[mvpd]};
                        })
                    }
                }));
            } else {
                console.log(response);
                setPrimaryOrder([]);
            }
        }).catch(error => {
            props.toast("error", `Error getting the top nine providers for ${props.brand}`);
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(function updateCollidingBrands() {
        if (primaryOrder) {
            const collidedMVPDs = new Set();
            for (let countryIndex = 0; countryIndex < primaryOrder.length; countryIndex++) {
                for (let j = 0; j < primaryOrder[countryIndex].providers.length; j++) {
                    for (let k = j + 1; k < primaryOrder[countryIndex].providers.length; k++) {
                        if (parseInt(primaryOrder[countryIndex].providers[k].position) === parseInt(primaryOrder[countryIndex].providers[j].position)) {
                            collidedMVPDs.add(primaryOrder[countryIndex].providers[j].provider);
                            collidedMVPDs.add(primaryOrder[countryIndex].providers[k].provider);
                        }
                    }
                }
            }

            setCollidingMVPDs(Array.from(collidedMVPDs));
        } else {
            setCollidingMVPDs([]);
        }
    }, [primaryOrder]);

    return (
        <Tab.Pane>
            {
                loading ? <Container textAlign="center"><Icon name="spinner" loading size="huge"/>Loading Top Providers...</Container> :
                primaryOrder ?
                    <Tab
                        panes={
                            primaryOrder.map(country => {
                                return {
                                    menuItem: {
                                        key: `${country.country}-primary-order`,
                                        content: <label>{country.country}</label>
                                    },
                                    render: () =>
                                        <ReactTable
                                            data={country.providers}
                                            columns={[
                                                {Header: "Provider", accessor: "provider"},
                                                {Header: "Position", accessor: "position"}
                                            ]}
                                            loading={loading}
                                            className="-striped -highlight"
                                            defaultPageSize={10}
                                            defaultSorted={[
                                                {
                                                    id: "position",
                                                    desc: false
                                                }, {
                                                    id: "provider",
                                                    desc: false
                                                }
                                            ]}
                                            getTrProps={(state, rowInfo, column, instance) => {
                                                return {
                                                    style: {
                                                        background: rowInfo && rowInfo.row && collidingMVPDs.includes(rowInfo.row.provider) ? "red" : ""
                                                    }
                                                }
                                            }}
                                        />
                                }
                            })
                        }
                    /> :
                    <Message warning icon>No providers were found for {props.brand}</Message>
            }

        </Tab.Pane>
    );
};
