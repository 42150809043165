import React, {useState, useEffect} from "react";
import {Button, Container, Input, Grid, Header, Modal, Table} from "semantic-ui-react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ReactTable from "react-table-v6";
import GroupMembershipEditor from "./GroupMembershipEditor";
import ContingentButton from "../../ContingentButton";

export default function GroupEntityEditorTab(props) {
    const [groups, setGroups] = useState([]);
    const [allProviders, setAllProviders] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState("");
    const [newGroupId, setNewGroupId] = useState("");
    const [newGroupDisplayName, setNewGroupDisplayName] = useState("");
    const [selectedGroupProviders, setSelectedGroupProviders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newClicked, setNewClicked] = useState(false);

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initializeGroups() {
        updateGroups();
    }, []);

    useEffect(function initializeProviders() {
        updateProviders();
    }, []);

    useEffect(function updateSelectedGroupOnIdChange() {
        setSelectedGroupProviders([]);
        if (selectedGroupId) {
            setLoading(true);
            TVEManagerDataProvider.getProvidersByGroup(selectedGroupId).then(response => {
                setSelectedGroupProviders(response.providers.sort((a, b) => {
                    if (a.displayName < b.displayName) return -1;
                    if (a.displayName > b.displayName) return 1;
                    return 0;
                }));
            }).catch(error => {
                props.toast("Error", `There was an error retrieving the providers for the group ${selectedGroupId}`, "error");
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [selectedGroupId, JSON.stringify(groups)]);

    const updateGroups = () => {
        setLoading(true);
        TVEManagerDataProvider.getGroups().then(response => {
            console.log("(GroupEntityEditorTab.updateGroups) response", response);
            if (response.groups) {
                setGroups(response.groups);
            } else {
                setGroups([]);
            }
        }).catch(error => {
            console.error("(GroupEntityEditorTab.updateGroups) error updating groups: ", error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const updateProviders = () => {
        TVEManagerDataProvider.getProviders().then(response => {
            if (response.providers) {
                setAllProviders(response.providers);
            } else {
                setAllProviders([]);
            }
        }).catch(error => {
            console.error(error);
            props.toast("Error", "There was an error updating the provider list.", "error");
        });
    };

    const onClickRefresh = () => {
        updateProviders();
        updateGroups();
    };

    const createGroup = () => {
        if (groups.find(group => group.group === newGroupId)) {
            props.toast("Error", "The group ID you provided already is in use. Please enter a different group ID.", "error");
        } else {
            TVEManagerDataProvider.createGroup(newGroupId, newGroupDisplayName).then(response => {
                console.log("(GroupEntityEditorTab.createGroup) response: ", response);
                setNewClicked(false);
                updateProviders();
                updateGroups();
            }).catch(error => {
                console.error(error);
                props.toast("Error", `There was an error creating the group ${selectedGroupId}`, "error");
            });
        }
    };

    const deleteClicked = groupId => {
        TVEManagerDataProvider.deleteGroup(groupId).then(response => {
            console.log("(GroupEntityEditorTab.deleteClicked) response: ", response);
            props.toast("Success", `Successfully deleted the group ${selectedGroupId}`, "success");
            updateProviders();
            updateGroups();
        }).catch(error => {
            console.error(error);
            props.toast("Error", `There was an error deleting ${groupId}.`, "error");
        });
    };

    return (
        <Grid>
            <Grid.Column className="masterContainer" width={7}>
                <Grid>
                    <Grid.Column floated="left" width={8}>
                        <Header as="h1">Group Editor</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                        <Container fluid textAlign="right">
                            <Button.Group>
                                <Button onClick={onClickRefresh} secondary>Refresh</Button>
                                <Button.Or />
                                <Modal
                                    onClose={() => {
                                        setNewClicked(false);
                                        setSelectedGroupId(newGroupId);
                                    }}
                                    onOpen={() => {
                                        setNewGroupId("");
                                        setNewGroupDisplayName("");
                                        setNewClicked(true);
                                    }}
                                    open={newClicked}
                                    trigger={
                                    <ContingentButton
                                        primary
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        user={props.user}
                                        allPermissions={props.permissions}
                                    >Create New Group</ContingentButton>}
                                >
                                    <Modal.Header><Container textAlign="center">Create New Group</Container></Modal.Header>
                                    <Modal.Content>
                                        <Container textAlign="center">
                                            Enter the name of the new group here. After you create the group, you can begin adding providers.
                                            <br />
                                            <Table>
                                                <Table.Row textAlign="right">
                                                    <Table.Cell width={4}>Group ID</Table.Cell>
                                                    <Table.Cell width={12}>
                                                        <Input
                                                            value={newGroupId}
                                                            fluid
                                                            onChange={(event, {value}) => setNewGroupId(value)}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row textAlign="right">
                                                    <Table.Cell width={4}>Group Name</Table.Cell>
                                                    <Table.Cell width={12}>
                                                        <Input
                                                            value={newGroupDisplayName}
                                                            fluid
                                                            onChange={(event, {value}) => setNewGroupDisplayName(value)}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table>
                                        </Container>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <ContingentButton
                                            secondary
                                            onClick={() => setNewClicked(false)}
                                            service={props.service}
                                            module={props.module}
                                            scope="all"
                                            user={props.user}
                                            allPermissions={props.permissions}
                                        >Cancel</ContingentButton>
                                        <ContingentButton
                                            primary
                                            onClick={createGroup}
                                            service={props.service}
                                            module={props.module}
                                            scope="all"
                                            user={props.user}
                                            allPermissions={props.permissions}
                                        >Create Group</ContingentButton>
                                    </Modal.Actions>
                                </Modal>
                            </Button.Group>
                            <br /><br />
                        </Container>
                    </Grid.Column>
                </Grid>
                <ReactTable
                    data={groups}
                    columns={[
                        {Header: "Group ID", accessor: "group"},
                        {Header: "Group Name", accessor: "displayName"},
                        {Header: "# Providers",
                            accessor: "providers",
                            width: 100,
                            Cell: row => {
                                return Array.isArray(row.value) && row.value.length > 0 ? row.value.length :
                                    <
                                        ContingentButton
                                        color="red"
                                        onClick={() => deleteClicked(row.original.group)}
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        user={props.user}
                                        allPermissions={props.permissions}
                                    >Delete</ContingentButton>
                            }
                        }
                    ]}
                    filterable
                    defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                setSelectedGroupId(rowInfo.row.group);
                            },
                            style: {
                                background: rowInfo && rowInfo.row && selectedGroupId === rowInfo.row.group ? "rgba(65, 83, 175, .5)" : ""
                            }
                        };
                    }}
                    className="-striped -highlight"
                    defaultPageSize={14}
                    loading={loading}
                />
            </Grid.Column>
            <Grid.Column className="detailsContainer" width={9}>
                <GroupMembershipEditor
                    toast={props.toast}
                    selectedGroupId={selectedGroupId}
                    TVEManagerDataProvider={TVEManagerDataProvider}
                    selectedGroupProviders={selectedGroupProviders}
                    setSelectedGroupProviders={setSelectedGroupProviders}
                    allProviders={allProviders}
                    allGroups={groups}
                    updateGroups={updateGroups}
                    userCanEdit={props.userCanEdit}
                />
            </Grid.Column>
        </Grid>
    );
};
