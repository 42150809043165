import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import BrandEntityEditorTab from "./BrandEntityEditorTab";
import PlatformEntityEditorTab from "./PlatformEntityEditorTab";
import ProviderEntityEditorTab from "./ProviderEntityEditorTab";
import GroupEntityEditorTab from "./GroupEntityEditorTab";

export default function TVEManagerEntityEditor(props) {
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            for (const permission of props.permissions) {
                const serviceMatches = permission.service === props.service;
                const moduleMatches = ["all", permission.module].includes(permission.module);
                const roleMatches = ["editor", "admin"].includes(permission.role);
                console.log("TVEManagerEntityEditor.updateUserCanEdit: service matches: ", serviceMatches, "module matches? ", moduleMatches, "role matches? ", roleMatches);
                if (serviceMatches && moduleMatches && roleMatches) {
                    updatedUserCanEdit = true;
                    break;
                }
            }
        }
        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.permissions), JSON.stringify(props.user)]);

    return(
        <Grid>
            <Grid.Column width={16} className="masterContainer">
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "brand-tab",
                                content: <label>Brand Editor</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <BrandEntityEditorTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            user={props.user}
                                            permissions={props.permissions}
                                            userCanEdit={userCanEdit}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "platform-tab",
                                content: <label>Platform Editor</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <PlatformEntityEditorTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            user={props.user}
                                            permissions={props.permissions}
                                            userCanEdit={userCanEdit}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "provider-tab",
                                content: <label>Provider Editor</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <ProviderEntityEditorTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            user={props.user}
                                            permissions={props.permissions}
                                            userCanEdit={userCanEdit}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "group-tab",
                                content: <label>Group Editor</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={props.user}
                                    permissionsRequired={props.permissionsRequired}
                                    authenticated={props.authenticated}
                                    checkIfAuthorized={props.checkIfAuthorized}
                                    component={
                                        <GroupEntityEditorTab
                                            toast={props.toast}
                                            service={props.service}
                                            module={props.module}
                                            user={props.user}
                                            permissions={props.permissions}
                                            userCanEdit={userCanEdit}
                                        />
                                    }
                                    service={props.service}
                                    module={props.module}
                                    permissions={props.permissions}
                                    userPermissions={props.userPermissions}
                                    loadingPermissions={props.loadingPermissions}
                                />
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    )
};
