import React, {useEffect, useState} from "react";
import {Icon, Input, Table, Container} from "semantic-ui-react";

export default function PrimaryOrderForm(props) {
    const [order, setOrder] = useState("");

    useEffect(() => {
        console.log(`updated order for ${props.brand} to ${props.order}`);
        if (props.order) {
            setOrder(props.order.toString());
        } else {
            setOrder("");
        }
    }, [props.order.toString()]);

    const updateOrder = newPrimaryOrder => {
        setOrder(newPrimaryOrder);
        props.setOrder(props.brand, newPrimaryOrder);
    };

    return (
        <Table.Row>
            <Table.Cell width={1} textAlign="center">
                {
                    props.isAdded === true ? <Container onClick={() => props.removeAddedBrand(props.brand)}><Icon name="minus" color="red" /></Container> : ""
                }
            </Table.Cell>
            <Table.Cell textAlign="right"><b>{props.brand}</b></Table.Cell>
            <Table.Cell>
                <Input
                    value={order}
                    onChange={(event, {value}) => updateOrder(value)}
                    type="number"
                    fluid
                />
            </Table.Cell>
        </Table.Row>
    );
};
