import React, {useState, useEffect} from "react";
import {Divider, Form, Input, Select} from "semantic-ui-react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ContingentButton from "../../ContingentButton";

export default function ProviderDarkphaseTab(props) {
    const [providerConfig, setProviderConfig] = useState({});
    const [brandOptions, setBrandOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [lines, setLines] = useState("");
    const [phoneType, setPhoneType] = useState("");
    const [phoneText, setPhoneText] = useState("");
    const [linkType, setLinkType] = useState("");
    const [linkText, setLinkText] = useState("");
    const [linkURL, setLinkURL] = useState("");
    const [isDarkphased, setIsDarkphased] = useState(false);

    useEffect(function getProviderConfigOnSelection() {
        if (props.selectedProviderId) {
            updateProviderConfig();
        } else {
            setProviderConfig({});
        }
    }, [props.selectedProviderId]);

    useEffect(function setBrandsOnProviderSelection() {
        if (providerConfig && providerConfig.hasOwnProperty("brands")) {
            setBrandOptions(Object.keys(providerConfig.brands).map(brand => {
                return {key: brand, text: brand, value: brand};
            }));
            setSelectedBrand("");
        } else {
            setBrandOptions([providerConfig]);
            setSelectedBrand("");
        }
    }, [JSON.stringify(providerConfig)]);

    useEffect(function updateDarkphaseOnBrandChange() {
        console.log(selectedBrand, providerConfig);
        if (selectedBrand && providerConfig && providerConfig.hasOwnProperty("brands") && providerConfig.brands.hasOwnProperty(selectedBrand)) {
            const darkphase = providerConfig.brands[selectedBrand].darkphase;
            const darkphaseFound = darkphase && true;
            console.log("darkphase update triggered, darkphase found?", darkphaseFound)
            setIsDarkphased(darkphaseFound);
            if (darkphaseFound && typeof darkphase === "object") {
                console.log(providerConfig.brands[selectedBrand]);
                setLines(darkphase.lines.join(","));
                setPhoneText(darkphase.meta.phone1.text);
                setPhoneType(darkphase.meta.phone1.type);
                setLinkType(darkphase.meta.link1.type);
                setLinkText(darkphase.meta.link1.text);
                setLinkURL(darkphase.meta.link1.url);
            } else {
                setLines("");
                setPhoneType("");
                setPhoneText("");
                setLinkText("");
                setLinkType("");
                setLinkURL("");
            }
        } else {
            setIsDarkphased(false);
            setLines("");
            setPhoneType("");
            setPhoneText("");
            setLinkText("");
            setLinkType("");
            setLinkURL("");
        }
    }, [selectedBrand, JSON.stringify(providerConfig)]);

    const saveDarkphase = async () => {
        await TVEManagerDataProvider.setDarkphase(props.selectedProviderId, selectedBrand, lines.split(","), phoneType, phoneText, linkType, linkText, linkURL).then(response => {
            console.log("(ProviderDarkphaseTab.removeDarkphase) response: ", response);
            props.refreshProviders();
            updateProviderConfig();
        });
    };

    const removeDarkphase = async () => {
        await TVEManagerDataProvider.removeDarkphase(props.selectedProviderId, selectedBrand).then(response => {
            console.log("(ProviderDarkphaseTab.removeDarkphase) response: ", response);
            props.refreshProviders();
            updateProviderConfig();
        });
    };

    const updateProviderConfig = () => {
        TVEManagerDataProvider.getProviderById(props.selectedProviderId).then(response => {
            setProviderConfig(response);
        });
    };

    return (
        <Form>
            <Form.Field
                control={Select}
                label="Brand"
                value={selectedBrand}
                options={brandOptions}
                onChange={(event, {value}) => setSelectedBrand(value)}
                fluid
            />
            <Form.Field
                label="Lines"
                control={Input}
                value={lines}
                onChange={(event, {value}) => setLines(value)}
                fluid
            />
            <Divider horizontal>Phone Data</Divider>
            <Form.Field
                control={Input}
                label="Phone Type"
                value={phoneType}
                onChange={(event, {value}) => setPhoneType(value)}
            />
            <Form.Field
                control={Input}
                label="Phone Text"
                value={phoneText}
                onChange={(event, {value}) => setPhoneText(value)}
            />
            <Divider horizontal>Link Data</Divider>
            <Form.Field
                control={Input}
                label="Link Type"
                value={linkType}
                onChange={(event, {value}) => setLinkType(value)}
            />
            <Form.Field
                control={Input}
                label="Link Text"
                value={linkText}
                onChange={(event, {value}) => setLinkText(value)}
            />
            <Form.Field
                control={Input}
                label={"Link URL"}
                value={linkURL}
                onChange={(event, {value}) => setLinkURL(value)}
            />
            <Form.Group widths="equal">
                <Form.Field>
                    <label>&nbsp;</label>
                    <ContingentButton
                        fluid
                        onClick={saveDarkphase}
                        primary
                        disabled={!props.selectedProviderId}
                        service={props.service}
                        module={props.module}
                        scope="all"
                        user={props.user}
                        allPermissions={props.permissions}
                    >Save</ContingentButton>
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <ContingentButton
                        fluid
                        onClick={removeDarkphase}
                        secondary
                        disabled={!isDarkphased}
                        service={props.service}
                        module={props.module}
                        scope={["all", "admin"]}
                        user={props.user}
                        allPermissions={props.permissions}
                    >Remove Darkphase</ContingentButton>
                </Form.Field>
            </Form.Group>
        </Form>
    );
};
