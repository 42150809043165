import React, {useState, useEffect} from "react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import {Button, Container, Grid, Header, Tab} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import ProviderBasicInfoTab from "./ProviderBasicInfoTab";
import ProviderDarkphaseTab from "./ProviderDarkphaseTab";
import ProviderCallbacksTab from "./ProviderCallbacksTab";
import ContingentButton from "../../ContingentButton";

export default function ProviderEntityEditorTab(props) {
    const [providers, setProviders] = useState([]);
    const [brands, setBrands] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedProviderId, setSelectedProviderId] = useState("");
    const [newClicked, setNewClicked] = useState(false);

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initializeProviders() {
        refreshProviders();
    }, []);

    useEffect(function initializeBrands() {
        TVEManagerDataProvider.getBrands().then(response => {
            if (response.brands) {
                setBrands(response.brands);
            } else {
                setBrands([]);
            }
        });
    }, []);

    useEffect(function initializeGroups() {
        TVEManagerDataProvider.getGroups().then(response => {
            if (response.groups) {
                setGroups(response.groups);
            } else {
                setGroups([]);
            }
        });
    }, []);

    const refreshProviders = () => {
        TVEManagerDataProvider.getProviders().then(response => {
            if (response.providers) {
                setProviders(response.providers);
            } else {
                setProviders([]);
            }
        }).catch(error => {
            console.error(error);
            props.toast("error", "There was an error retrieving the providers from the TVE Manager.");
        });
    };

    const onClickNew = () => {
        setNewClicked(true);
        setSelectedProviderId("");
    };

    return (
        <Grid>
            <Grid.Column width={8} className="masterContainer">
                <Grid>
                    <Grid.Column floated="left" width={8}>
                        <Header as="h1">Provider Editor</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                        <Container fluid textAlign="right">
                            <Button.Group>
                                <ContingentButton
                                    onClick={onClickNew}
                                    primary
                                    service={props.service}
                                    module={props.module}
                                    scope={["all", "admin"]}
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >New Provider</ContingentButton>
                                <Button.Or text=""/>
                                <Button onClick={refreshProviders} secondary>Refresh</Button>
                            </Button.Group>
                            <br /><br />
                        </Container>
                    </Grid.Column>
                </Grid>
                <ReactTable
                    data={providers}
                    columns={[
                        {Header: "Provider ID", accessor: "id"},
                        {Header: "Display Name", accessor: "displayName"},
                        {
                            Header: "Is Darkphased?",
                            accessor: "isDarkphased",
                            filterMethod: (filter, row) => row.isDarkphased.toString().includes(filter.value),
                            Cell: row =>
                                row.value === true ?
                                    <Container style={{textAlign: "center"}}>true</Container> :
                                row.value === false ?
                                    <Container style={{textAlign: "center"}}>false</Container> :
                                    <Container textAlign="center">?</Container>
                        }
                    ]}
                    filterable
                    defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                setSelectedProviderId(rowInfo.row.id);
                                setNewClicked(false);
                            },
                            style: {
                                background: rowInfo && rowInfo.row && selectedProviderId === rowInfo.row.id ? "rgba(65, 83, 175, .5)" : ""
                            }
                        }
                    }}
                    className="-striped -highlight"
                    defaultPageSize={14}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100, 500, 1000]}
                />
            </Grid.Column>
            <Grid.Column width={8} className="detailsContainer">
                <Tab
                    panes={[
                        {
                            menuItem: {
                                key: "provider-basic-info-tab",
                                content: <label>Basic Provider Info</label>
                            },
                            render: () =>
                                <ProviderBasicInfoTab
                                    selectedProviderId={selectedProviderId}
                                    setSelectedProviderId={setSelectedProviderId}
                                    providers={providers}
                                    groups={groups}
                                    newClicked={newClicked}
                                    setNewClicked={setNewClicked}
                                    refreshProviders={refreshProviders}
                                    toast={props.toast}
                                    brands={brands}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    permissions={props.permissions}
                                    userCanEdit={props.userCanEdit}
                                />
                        },
                        {
                            menuItem: {
                                key: "provider-darkphase-tab",
                                content: <label>Darkphase</label>
                            },
                            render: () =>
                                <ProviderDarkphaseTab
                                    selectedProviderId={selectedProviderId}
                                    refreshProviders={refreshProviders}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    permissions={props.permissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "provider-callback-url-tab",
                                content: <label>Callback URLs</label>
                            },
                            render: () =>
                                <ProviderCallbacksTab
                                    selectedProviderId={selectedProviderId}
                                    providers={providers}
                                    TVEManagerDataProvider={TVEManagerDataProvider}
                                    toast={props.toast}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    permissions={props.permissions}
                                    userCanEdit={props.userCanEdit}
                                />
                        }
                    ]}
                />
            </Grid.Column>
        </Grid>
    );
};
