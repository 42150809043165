import React, {useState, useEffect} from "react";
import {Form, Message, Popup, Select, Table} from "semantic-ui-react";
import ProviderCallbackForm from "./ProviderCallbackForm";
import ContingentButton from "../../ContingentButton";

export default function ProviderCallbacksTab(props) {
    const [provider, setProvider] = useState({});
    const [brandOptions, setBrandOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [platformCallbackUrls, setPlatformCallbackUrls] = useState({});
    const [originalCallbackUrls, setOriginalCallbackUrls] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(function updateProvider() {
        if (props.selectedProviderId) {
            props.TVEManagerDataProvider.getProviderById(props.selectedProviderId).then(response => {
                setProvider(response);
            }).catch(error => {
                setProvider({});
                props.toast("error", `Error getting information for ${props.selectedProviderId}`);
                console.error(error);
            });
        } else {
            setProvider({});
        }
    }, [props.selectedProviderId]);

    useEffect(function unsavedChangesFound() {
        setUnsavedChanges(JSON.stringify(platformCallbackUrls) !== JSON.stringify(originalCallbackUrls));
    }, [JSON.stringify(platformCallbackUrls), JSON.stringify(originalCallbackUrls)]);

    useEffect(function updateBrandOptions() {
        console.log(provider);
        if (provider && provider.brands) {
            setBrandOptions(Object.keys(provider.brands).map(brand => {
                return {key: provider.brands[brand].id, text: provider.brands[brand].displayName, value: provider.brands[brand].id};
            }));
        }
    }, [JSON.stringify(provider)]);

    useEffect(function updateCallbackUrls() {
        if (provider && selectedBrand) {
            let brand;
            const updatedCallbackUrls = {};
            for (const brandName of Object.keys(provider.brands)) {
                if (provider.brands[brandName].id === selectedBrand) {
                    brand = provider.brands[brandName];
                    break;
                }
            }

            if (brand) {
                console.log(brand)
                for (const platform of brand.platforms) {
                    updatedCallbackUrls[platform.id] = platform.callbackUrl;
                }
            }
            setPlatformCallbackUrls(updatedCallbackUrls);
            setOriginalCallbackUrls(Object.assign(updatedCallbackUrls));
        }
    }, [JSON.stringify(provider), selectedBrand]);

    const setCallbackUrl = (platform, callbackUrl) => {
        const updatedPlatformCallbackUrls = platformCallbackUrls;
        updatedPlatformCallbackUrls[platform] = callbackUrl;
        setPlatformCallbackUrls(updatedPlatformCallbackUrls);
        console.log(platformCallbackUrls);
    };

    const saveCallbackUrls = () => {
        console.log(platformCallbackUrls);
        const callbackUrls = [];
        for (const platform of Object.keys(platformCallbackUrls)) {
            callbackUrls.push({
                platformId: platform,
                callbackUrl: platformCallbackUrls[platform]
            });
        }

        props.TVEManagerDataProvider.updateCallbackUrls(props.selectedProviderId, selectedBrand, callbackUrls).then(response => {
            console.log(response);
            setOriginalCallbackUrls(Object.assign(platformCallbackUrls));
            props.toast("Success", response.message, "success");
        }).catch(error => {
            console.error("(ProviderCallbacksTab.saveCallbackUrls) error: ", error);
            props.toast("error", `Error encountered while updating callback URL for MVPD ${props.selectedProviderId}, ${selectedBrand}, and platform ${platform}`);
        });
    };

    return (
        <Form>
            <Form.Group>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Popup
                        content={<Message compact color="green">The selected template has unsaved changes.</Message>}
                        open={unsavedChanges && props.userCanEdit}
                        trigger={
                        <ContingentButton
                            onClick={saveCallbackUrls}
                            fluid
                            primary
                            disabled={!props.selectedProviderId || !selectedBrand || !props.userCanEdit}
                            service={props.service}
                            module={props.module}
                            scope={["all", "admin"]}
                            user={props.user}
                            allPermissions={props.permissions}
                        >Save</ContingentButton>}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field
                control={Select}
                label="Brand"
                value={selectedBrand}
                options={brandOptions}
                onChange={(event, {value}) => setSelectedBrand(value)}
                fluid
            />
            <Table compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={4}>Platform</Table.HeaderCell>
                        <Table.HeaderCell width={12}>Callback URL</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        Object.keys(platformCallbackUrls).map(platform =>
                            <ProviderCallbackForm
                                key={`${props.selectedProviderId}-callback-form-${selectedBrand}-${platform}`}
                                platform={platform}
                                callbackUrl={platformCallbackUrls[platform]}
                                setCallbackUrl={setCallbackUrl}
                            />
                        )
                    }
                </Table.Body>
            </Table>
        </Form>
    );
};
