import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Header, Icon, Input, Message, Popup} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ContingentButton from "../../ContingentButton";

export default function PlatformEntityEditorTab(props) {
    const [platforms, setPlatforms] = useState([]);
    const [selectedPlatformDisplayName, setSelectedPlatformDisplayName] = useState("");
    const [selectedPlatformId, setSelectedPlatformId] = useState("");
    const [selectedPlatformInitialInfo, setSelectedPlatformInitialInfo] = useState({});
    const [newClicked, setNewClicked] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initializePlatforms() {
        refreshPlatforms();
    }, []);

    useEffect(function updateUnsavedChanges() {
        const platformIdUpdated = selectedPlatformId !== selectedPlatformInitialInfo.id;
        const displayNameUpdated = selectedPlatformDisplayName !== selectedPlatformInitialInfo.displayName;
        setUnsavedChanges(selectedPlatformId.length > 0 && (platformIdUpdated || displayNameUpdated));

    }, [selectedPlatformId, selectedPlatformDisplayName, JSON.stringify(selectedPlatformInitialInfo)]);

    useEffect(function onPlatformUpdate() {
        for (const platform of platforms) {
            if (platform.id === selectedPlatformId) {
                setSelectedPlatformDisplayName(platform.displayName);
                setSelectedPlatformInitialInfo(Object.assign({}, platform));
                break;
            }
        }
    }, [JSON.stringify(platforms)]);

    const refreshPlatforms = () => {
        TVEManagerDataProvider.getPlatforms().then(response => {
            if (response.platforms) {
                setPlatforms(response.platforms);
            } else {
                setPlatforms([]);
            }
        }).catch(error => {
            console.error(error);
            props.toast("error", "There was an error retrieving the platforms from the TVE Manager.");
        });
    };

    const onClickNew = () => {
        setNewClicked(true);
        setSelectedPlatformId("");
        setSelectedPlatformDisplayName("");
    };

    const savePlatform = () => {
        if (!selectedPlatformId) {
            props.toast("error", "Please enter a platform id in order to continue.");
        } else if (!selectedPlatformDisplayName) {
            props.toast("error", "Please enter a platform display name in order to continue.");
        } else if (newClicked) {
            if (platforms.find(platform => platform.id === selectedPlatformId)) {
                props.toast("Error", "The platform ID you provided already is in use. Please enter a different platform ID.", "error");
            } else {
                TVEManagerDataProvider.createPlatform(selectedPlatformId, selectedPlatformDisplayName).then(response => {
                    refreshPlatforms();
                    props.toast("success", "Platform Created");
                }).catch(error => {
                    console.error("(PlatformEntityEditor.savePlatform) error", error);
                    props.toast("error", "Error creating new platform");
                });
            }
        } else {
            TVEManagerDataProvider.savePlatform(selectedPlatformId, selectedPlatformDisplayName).then(response => {
                refreshPlatforms();
                props.toast("success", "Platform saved");
            }).catch(error => {
                props.toast("error", "Error saving platform");
                console.error("PlatformEntityEditorTab.savePlatform error: ", error);
            });
        }
    };

    return (
        <Grid>
            <Grid.Column width={11} className="masterContainer">
                <Grid>
                    <Grid.Column floated="left" width={8}>
                        <Header as="h1">Platform Editor</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                        <Container fluid textAlign="right">
                            <Button.Group>
                                <ContingentButton
                                    onClick={onClickNew}
                                    primary
                                    service={props.service}
                                    module={props.module}
                                    scope={["all", "admin"]}
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >New Platform</ContingentButton>
                                <Button.Or text=""/>
                                <Button onClick={refreshPlatforms} secondary>Refresh</Button>
                            </Button.Group>
                            <br /><br />
                        </Container>
                    </Grid.Column>
                </Grid>
                <ReactTable
                    data={platforms}
                    columns={[
                        {Header: "Platform ID", accessor: "id"},
                        {Header: "Display Name", accessor: "displayName"}
                    ]}
                    filterable
                    defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                setSelectedPlatformId(rowInfo.row.id);
                                setSelectedPlatformDisplayName(rowInfo.row.displayName);
                                setSelectedPlatformInitialInfo(rowInfo.row._original);
                                setNewClicked(false);
                            },
                            style: {
                                background: rowInfo && rowInfo.row && selectedPlatformId === rowInfo.row.id ? "rgba(65, 83, 175, .5)" : ""
                            }
                        }
                    }}
                    className="-striped -highlight"
                    defaultPageSize={14}
                />
            </Grid.Column>
            <Grid.Column width={5} className="detailsContainer">
                <Form>
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Popup
                            content={<Message compact color="green">You have made unsaved changes to this platform.</Message>}
                            trigger={
                            <ContingentButton
                                onClick={savePlatform}
                                primary
                                fluid
                                disabled={selectedPlatformId.length < 1 || !unsavedChanges || (newClicked && selectedPlatformId.match(/\W/)) || !props.userCanEdit}
                                service={props.service}
                                module={props.module}
                                scope={["all", "admin"]}
                                user={props.user}
                                allPermissions={props.permissions}
                            >{newClicked ? "Create" : "Save"}</ContingentButton>}
                            open={unsavedChanges && props.userCanEdit}
                        />
                    </Form.Field>
                    <Popup
                        trigger={
                            <Form.Field
                                label="Platform ID"
                                control={Input}
                                readOnly={!newClicked}
                                value={selectedPlatformId}
                                icon={<Icon name={newClicked ? "unlock alternate" : "lock"} color={newClicked ? "black" : "red"} />}
                                iconPosition="left"
                                onChange={(event, {value}) => setSelectedPlatformId(value)}
                            />
                        }
                        open={selectedPlatformId.match(/\W/)}
                        style={{backgroundColor: "red"}}
                        inverted
                        content="The platform ID cannot contain spaces or special characters."
                    />
                    <Form.Field
                        label="Platform Display Name"
                        control={Input}
                        value={selectedPlatformDisplayName}
                        onChange={(event, {value}) => setSelectedPlatformDisplayName(value)}
                    />
                </Form>
            </Grid.Column>
        </Grid>
    );
};
