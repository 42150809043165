import React, {useState, useEffect} from "react";
import {Button, Form, Icon, Input, Message, Popup, Radio, Select, Tab} from "semantic-ui-react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ProviderPrimaryOrderTable from "./ProviderPrimaryOrderTable";
import ContingentButton from "../../ContingentButton";

const COUNTRY_OPTIONS = [
    {key: "US", text: "United States", value: "US"},
    {key: "CA", text: "Canada", value: "CA"},
    {key: "US|CA", text: "US/Canada", value: "US|CA"}
];

export default function ProviderBasicInfoTab(props) {
    const [initialProviderInformation, setInitialProviderInformation] = useState({});
    const [selectedProviderDisplayName, setSelectedProviderDisplayName] = useState("");
    const [selectedProviderCountry, setSelectedProviderCountry] = useState("");
    const [cobrandEnabled, setCobrandEnabled] = useState(false);
    const [cobrandToggled, setCobrandToggled] = useState(false);
    const [loadingProviderInfo, setLoadingProviderInfo] = useState(false);
    const [brands, setBrands] = useState([]);
    const [originalPrimaryOrders, setOriginalPrimaryOrders] = useState({});
    const [primaryOrderUpdated, setPrimaryOrderUpdated] = useState(false);
    const [providerVisible, setProviderVisible] = useState(false);
    const [providerVisibilityToggled, setProviderVisibilityToggled] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [availableBrands, setAvailableBrands] = useState([]);
    const [brandOptions, setBrandOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [addedBrands, setAddedBrands] = useState({});
    const [updatingProviderBasicInfo, setUpdatingProviderBasicInfo] = useState(false);
    const [updatingProviderMessage, setUpdatingProviderMessage] = useState("");

    useEffect(function onProviderIdChange() {
        if (props.selectedProviderId && !props.newClicked) {
            getProviderInformation();
        }
    }, [props.selectedProviderId, JSON.stringify(props.providers)]);

    useEffect(function updateBrandOptions() {
        const currentBrandIds = [];
        for (const brand of brands) {
            currentBrandIds.push(brand.id);
        }

        setAvailableBrands(props.brands.filter(brand => !currentBrandIds.includes(brand.id)));
    }, [JSON.stringify(brands), JSON.stringify(props.brands)]);

    useEffect(function clearOnClickNew() {
        if (props.newClicked) {
            setInitialProviderInformation({});
            setSelectedProviderDisplayName("");
            setSelectedProviderCountry("");
            setCobrandEnabled(false);
            setCobrandToggled(false);
            setBrands([]);
            setAddedBrands([]);
            setOriginalPrimaryOrders({});
            setPrimaryOrderUpdated(false);
            setProviderVisible(true);
            setProviderVisibilityToggled(false);
            setSelectedGroup("");
            setUnsavedChanges(false);
        }
    }, [props.newClicked]);

    useEffect(function initializeBrandOptions() {
        setBrandOptions(availableBrands.map(brand => {
            return {key: brand.id, text: brand.displayName, value: brand.id};
        }));
    }, [JSON.stringify(availableBrands)]);

    useEffect(function initializeGroupOptions() {
        setGroupOptions([{key: "no-group", text: "None", value: ""}].concat(props.groups.map(group => {
            return {key: group.group, text: group.displayName, value: group.group};
        })));
    }, [JSON.stringify(props.groups)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(false);
        if (props.newClicked) {
            setUnsavedChanges(selectedProviderDisplayName !== "" || props.selectedProviderId !== "" || selectedProviderCountry !== "" || cobrandToggled || providerVisibilityToggled || Object.keys(addedBrands).length > 0 || selectedGroup.length > 0);
        } else if (props.selectedProviderId && !loadingProviderInfo && Object.keys(initialProviderInformation).length > 0) {
            const displayNameUpdated = initialProviderInformation.displayName !== selectedProviderDisplayName;
            const countryUpdated = initialProviderInformation.country !== selectedProviderCountry;
            const idUpdated = initialProviderInformation.id !== props.selectedProviderId;
            const brandsWereAdded = Object.keys(addedBrands).length > 0;
            const groupUpdated = initialProviderInformation.group !== selectedGroup;
            setUnsavedChanges(displayNameUpdated || countryUpdated || idUpdated || cobrandToggled || primaryOrderUpdated || providerVisibilityToggled || brandsWereAdded || groupUpdated);
        }
    }, [props.selectedProviderId, loadingProviderInfo, JSON.stringify(initialProviderInformation), selectedProviderDisplayName, selectedProviderCountry, cobrandToggled, providerVisible, primaryOrderUpdated, selectedGroup])

    const addBrandsToProvider = () => {
        if (props.selectedProviderId && Object.keys(addedBrands).length > 0) {
            const addedBrandsList = [];
            for (const brand of Object.keys(addedBrands)) {
                addedBrandsList.push({
                    id: brand,
                    primaryOrder: addedBrands[brand]
                });
            }

            setUpdatingProviderBasicInfo(true);
            setUpdatingProviderMessage(`The following brands are being added to the provider ${props.selectedProviderId}: ${addedBrandsList.map(brand => brand.id).join(", ")}.`);
            TVEManagerDataProvider.addBrandsToConfig(props.selectedProviderId, addedBrandsList).then(response => {
                console.log("(ProviderBasicInfoTab.addBrandsToProvider) response: ", response);
                getProviderInformation();
                setAddedBrands({});
            }).catch(error => {
                console.error("(ProviderBasicInfoTab.addBrandsToProvider) error: ", error);
                props.toast("Error", `There was an error adding brands to the provider ${props.selectedProviderId}`, "error");
            }).finally(() => {
                setUpdatingProviderBasicInfo(false);
                setUpdatingProviderMessage("");
            });
        }
    };

    const saveProvider = () => {
        if (!props.selectedProviderId) {
            props.toast("error", "Please enter a provider id to continue.");
        } else if (!selectedProviderDisplayName) {
            props.toast("error", "Please enter a provider display name in order to continue.");
        } else if (!selectedProviderCountry) {
            props.toast("error", "Please select a this provider's operating country or countries in order to continue.");
        } else if (props.newClicked) {
            if (props.providers.find(provider => provider.id === props.selectedProviderId)) {
                props.toast("Error", "The provider ID you provided already is in use. Please enter a different provider ID.", "error");
            } else {
                setUpdatingProviderBasicInfo(true);
                setUpdatingProviderMessage(`The provider ${props.selectedProviderId} is being created.`);
                TVEManagerDataProvider.createProvider(props.selectedProviderId, selectedProviderDisplayName, selectedProviderCountry, selectedGroup, providerVisible).then(response => {
                    console.log("(ProviderBasicInfoTab.saveProvider) create provider response: ", response);
                    props.refreshProviders();
                    getProviderInformation();
                    props.setNewClicked(false);
                    props.toast("Success", "Provider saved", "success");
                }).catch(error => {
                    props.toast("error", "Error saving platform", "error");
                    console.error("PlatformEntityEditorTab.savePlatform error: ", error);
                }).finally(() => {
                    setUpdatingProviderBasicInfo(false);
                    setUpdatingProviderMessage("");
                });
            }
        } else {
            if (cobrandToggled) {
                setUpdatingProviderBasicInfo(true);
                setUpdatingProviderMessage(`Legacy cobranding is currently being ${cobrandEnabled ? "disabled" : "enabled"}.`);
                if (cobrandEnabled) {
                    TVEManagerDataProvider.disableCobrandData(props.selectedProviderId).then(response => {
                        console.log("(ProviderBasicInfoTab.saveProvider) disableCobrandData response: ", response);
                        props.toast("success", `Cobranding has been disabled for the provider ${props.selectedProviderId}`);
                        setCobrandEnabled(false);
                        setCobrandToggled(false);
                    }).catch(error => {
                        console.error("(ProviderBasicInfoTab.saveProvider)", error);
                        props.toast("error", `Error disabling cobranding for the provider ${props.selectedProviderId}`);
                    }).finally(() => {
                        setUpdatingProviderBasicInfo(false);
                        setUpdatingProviderMessage("");
                    });
                } else {
                    TVEManagerDataProvider.enableCobrandData(props.selectedProviderId).then(response => {
                        console.log("(ProviderBasicInfoTab.saveProvider) enableCobrandData response: ", response);
                        props.toast("success", `Cobranding has been enabled for the provider ${props.selectedProviderId}`);
                        setCobrandEnabled(true);
                        setCobrandToggled(false);
                    }).catch(error => {
                        console.error("(ProviderBasicInfoTab.saveProvider)", error);
                        props.toast("error", `Error enabling cobranding for the provider ${props.selectedProviderId}`);
                    }).finally(() => {
                        setUpdatingProviderBasicInfo(false);
                        setUpdatingProviderMessage("");
                    });
                }
            }

            const primaryOrderUpdates = {};
            for (const brand of brands) {
                primaryOrderUpdates[brand.id] = brand.primaryOrder;
            }

            setUpdatingProviderBasicInfo(true);
            setUpdatingProviderMessage(`The information for the provider ${props.selectedProviderId} is currently being updated...`);
            TVEManagerDataProvider.updateProviderBasicInfo(props.selectedProviderId, selectedProviderDisplayName, selectedProviderCountry, providerVisible, selectedGroup, primaryOrderUpdates).then(response => {
                setProviderVisibilityToggled(false);
                console.log("(ProviderBasicInfoTab.saveProvider) response: ", response);
                props.refreshProviders();
                getProviderInformation();
                props.toast("Success", "Provider saved", "success");
            }).catch(error => {
                props.toast("error", "Error saving platform");
                console.error("PlatformEntityEditorTab.savePlatform error: ", error);
            }).finally(() => {
                setUpdatingProviderBasicInfo(false);
                setUpdatingProviderMessage("");
            });
        }

        addBrandsToProvider();
    };

    const updateAddedBrand = (brandName, order) => {
        setAddedBrands(Object.assign({}, addedBrands, {[brandName]: order}));
    };

    const removeAddedBrand = brand => {
        const {[brand]: deletedKey, ...remainingAddedBrands} = addedBrands;
        setAddedBrands(Object.assign({}, remainingAddedBrands));
    };

    const updatePrimaryOrder = (brandName, order) => {
        let currentBrands = brands;
        for (const brand of currentBrands) {
            if (brand.id === brandName) {
                brand.primaryOrder = order.toString();
            }
        }
        setBrands(currentBrands);
        checkPrimaryOrderUpdated();
    };

    const checkPrimaryOrderUpdated = () => {
        if (Object.keys(addedBrands).length > 0) {
            setPrimaryOrderUpdated(true);
        } else {
            let isPrimaryOrderUpdated = false;
            for (const brand of brands) {
                if (!originalPrimaryOrders.hasOwnProperty(brand.id) || !originalPrimaryOrders[brand.id]) {
                    isPrimaryOrderUpdated |= !!brand.primaryOrder;
                } else {
                    isPrimaryOrderUpdated |= brand.primaryOrder.toString() !== originalPrimaryOrders[brand.id].toString();
                }
            }
            setPrimaryOrderUpdated(isPrimaryOrderUpdated);
        }
    }

    const getProviderInformation = () => {
        setLoadingProviderInfo(true);
        setCobrandToggled(false);
        setOriginalPrimaryOrders({});
        setPrimaryOrderUpdated(false);
        setSelectedGroup("");
        setProviderVisibilityToggled(false);
        setAddedBrands([]);
        setUpdatingProviderBasicInfo(true);
        setUpdatingProviderMessage(`Getting information for the provider ${props.selectedProviderId}.`);

        TVEManagerDataProvider.getProviderById(props.selectedProviderId).then(response => {
            setSelectedProviderDisplayName(response.displayName);
            setSelectedGroup(response.group || "");
            setSelectedProviderCountry(response.country);
            setProviderVisible(response.visible);
            const brandOrders = {};
            const updatedBrands = [];
            for (const brand of Object.keys(response.brands)) {
                brandOrders[brand] = response.brands[brand].primaryOrder;
                updatedBrands.push(response.brands[brand]);
            }
            setBrands(updatedBrands);
            setOriginalPrimaryOrders(brandOrders);
            setInitialProviderInformation({
                displayName: response.displayName,
                country: response.country,
                id: response.id,
                brands: response.brands,
                group: response.group || ""
            });
        }).then(async () => {
            setUpdatingProviderBasicInfo(true);
            setUpdatingProviderMessage(`Getting cobranding information for ${props.selectedProviderId}`);
            await TVEManagerDataProvider.getCobrandDataByProvider(props.selectedProviderId).then(response => {
                if (Array.isArray(response.result) && response.result.length > 0) {
                    const result = response.result.pop();
                    setCobrandEnabled(result && (!result.hasOwnProperty("visible") || (result.visible && true)))
                } else {
                    setCobrandEnabled(false);
                }
                setLoadingProviderInfo(false);
            });
        }).finally(() => {
            setUpdatingProviderBasicInfo(false);
            setUpdatingProviderMessage("");
        });
    };

    const toggleVisibility = visible => {
        setProviderVisible(visible);
        setProviderVisibilityToggled(!providerVisibilityToggled);
    }

    return (
        <Tab.Pane>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Popup
                            content={<Message compact color="green">You have made unsaved changes to this provider.</Message>}
                            trigger={
                            <ContingentButton
                                onClick={saveProvider}
                                primary
                                fluid
                                disabled={!props.selectedProviderId || !unsavedChanges || !props.userCanEdit}
                                service={props.service}
                                module={props.module}
                                scope={["all", "admin"]}
                                user={props.user}
                                allPermissions={props.permissions}
                            >{props.newClicked ? "Create" : "Save"}</ContingentButton>}
                            open={unsavedChanges && props.userCanEdit}
                        />
                    </Form.Field>
                    <Form.Field
                        label="Provider ID"
                        control={Input}
                        readOnly={!props.newClicked}
                        value={props.selectedProviderId}
                        icon={<Icon name={props.newClicked ? "unlock alternate" : "lock"} color={props.newClicked ? "black" : "red"} />}
                        iconPosition="left"
                        onChange={(event, {value}) => props.setSelectedProviderId(value)}
                    />
                    <Form.Field
                        label="Country"
                        control={Select}
                        options={COUNTRY_OPTIONS}
                        value={selectedProviderCountry}
                        onChange={(event, {value}) => setSelectedProviderCountry(value)}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        label="Provider Display Name"
                        control={Input}
                        value={selectedProviderDisplayName}
                        onChange={(event, {value}) => setSelectedProviderDisplayName(value)}
                    />
                    <Form.Field>
                        <label>Global Toggles</label>
                        <Form.Field
                            label="Use Legacy Cobranding"
                            control={Radio}
                            toggle
                            checked={cobrandEnabled ? !cobrandToggled : cobrandToggled}
                            onChange={(event, {checked}) => setCobrandToggled(!cobrandToggled)}
                        />
                        <Form.Field
                            label="Provider Visible"
                            control={Radio}
                            toggle
                            checked={providerVisible}
                            onChange={(event, {checked}) => toggleVisibility(!providerVisible)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        label="Add Provider to Group"
                        control={Select}
                        options={groupOptions}
                        onChange={(event, {value}) => setSelectedGroup(value)}
                        value={selectedGroup}
                        fluid
                    />
                    <Form.Field
                        label="Select Brand to Add"
                        control={Select}
                        options={brandOptions}
                        onChange={(event, {value}) => setSelectedBrand(value)}
                        fluid
                    />
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Button
                            primary
                            onClick={() => setAddedBrands(Object.assign({}, addedBrands, {[selectedBrand]: ""}))}
                        >Add</Button>
                    </Form.Field>
                </Form.Group>
                {
                    updatingProviderBasicInfo ?
                        <Message icon>
                            <Message.Content>
                                <Icon name="spinner" loading size="huge" />
                                {updatingProviderMessage}
                            </Message.Content>
                        </Message> :
                        <ProviderPrimaryOrderTable
                            brands={brands}
                            addedBrands={addedBrands}
                            updatePrimaryOrder={updatePrimaryOrder}
                            updateAddedBrand={updateAddedBrand}
                            removeAddedBrand={removeAddedBrand}
                            checkPrimaryOrdersUpdated={checkPrimaryOrderUpdated}
                        />
                }
            </Form>
        </Tab.Pane>
    );
};
