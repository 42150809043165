import React, {useState, useEffect} from "react";
import {Input, Table} from "semantic-ui-react";

export default function ProviderCallbackForm(props) {
    const [callbackUrl, setCallbackUrl] = useState("");

    useEffect(() => {
        if (props.callbackUrl) {
            setCallbackUrl(props.callbackUrl || "");
        } else {
            setCallbackUrl("");
        }
    }, [props.callbackUrl]);

    const updateCallbackUrl = (updatedCallbackUrl) => {
        setCallbackUrl(updatedCallbackUrl);
        props.setCallbackUrl(props.platform, updatedCallbackUrl);
    };

    return (
        <Table.Row>
            <Table.Cell>{props.platform}</Table.Cell>
            <Table.Cell>
                <Input
                    value={callbackUrl}
                    onChange={(event, {value}) => updateCallbackUrl(value)}
                    fluid
                />
            </Table.Cell>
        </Table.Row>
    );
};
