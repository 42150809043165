import React, {useState, useEffect} from "react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import {Button, Container, Form, Grid, Header, Icon, Input, List, ListItem, Message, Popup, Segment} from "semantic-ui-react";
import ReactTable from "react-table-v6";
import BrandTopNineSubComponentTab from "./BrandTopNineSubComponentTab";
import ContingentButton from "../../ContingentButton";

export default function BrandEntityEditorTab(props) {
    const [brands, setBrands] = useState([]);
    const [selectedBrandDisplayName, setSelectedBrandDisplayName] = useState("");
    const [selectedProgrammerID, setSelectedProgrammerID] = useState("");
    const [selectedBrandId, setSelectedBrandId] = useState("");
    const [primaryImageTemplate, setPrimaryImageTemplate] = useState("");
    const [cobrandingImageTemplate, setCobrandingImageTemplate] = useState("");
    const [selectedBrandDefaultPlatforms, setSelectedBrandDefaultPlatforms] = useState([]);
    const [newClicked, setNewClicked] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [remainingPlatforms, setRemainingPlatforms] = useState([]);
    const [originalBrandData, setOriginalBrandData] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

    useEffect(function initializePlatforms() {
        TVEManagerDataProvider.getPlatforms().then(response => {
            console.log(response);
            setPlatforms(response.platforms ? response.platforms : []);
        });
    }, []);

    useEffect(function initializeBrands() {
        refreshBrands();
    }, []);

    useEffect(function updateRemainingPlatforms() {
        if (selectedBrandId || newClicked) {
            setRemainingPlatforms(platforms.filter(platform => !selectedBrandDefaultPlatforms.includes(platform.id)).map(platform => platform.id));
        } else {
            setRemainingPlatforms([]);
        }
    }, [selectedBrandDefaultPlatforms, JSON.stringify(platforms)]);

    useEffect(function updateUnsavedChanges() {
        if (originalBrandData) {
            const displayNameChanged = originalBrandData.displayName !== selectedBrandDisplayName;
            const programmerIdChanged = originalBrandData.brand !== selectedProgrammerID;
            const idChanged = originalBrandData.id !== selectedBrandId;
            const primaryImageTemplateChanged = originalBrandData.primaryImgTemplate !== primaryImageTemplate;
            const cobrandingImageTemplateChanged = originalBrandData.cobrandingImgTemplate !== cobrandingImageTemplate;
            let brandUpdated = displayNameChanged || programmerIdChanged || idChanged || primaryImageTemplateChanged || cobrandingImageTemplateChanged;
            if (originalBrandData.defaultPlatforms) {
                const defaultPlatformsChanged = JSON.stringify(originalBrandData.defaultPlatforms.sort()) !== JSON.stringify(selectedBrandDefaultPlatforms.sort());
                brandUpdated ||= defaultPlatformsChanged;
            }
            setUnsavedChanges(selectedBrandId.length > 0 && brandUpdated);
        } else {
            setUnsavedChanges(false);
        }
    }, [selectedBrandId, JSON.stringify(originalBrandData), selectedBrandDisplayName, primaryImageTemplate, selectedProgrammerID, cobrandingImageTemplate, JSON.stringify(selectedBrandDefaultPlatforms)])

    const refreshBrands = () => {
        TVEManagerDataProvider.getBrands().then(response => {
            if (response.brands) {
                setBrands(response.brands);
            } else {
                setBrands([]);
            }
        }).catch(error => {
            console.error(error);
            props.toast("error", "There was an error retrieving the brands from the TVE Manager.");
        });
    };

    const onClickNew = () => {
        setNewClicked(true);
        setSelectedBrandId("");
        setSelectedProgrammerID("");
        setSelectedBrandDisplayName("");
        setSelectedBrandDefaultPlatforms([]);
        setPrimaryImageTemplate("");
        setCobrandingImageTemplate("");
        setOriginalBrandData({});
    };

    const saveBrand = () => {
        if (!selectedBrandId) {
            props.toast("error", "Please enter a brand id to continue.");
        } else if (!selectedProgrammerID) {
            props.toast("error", "Please enter a programmer id to continue.");
        } else if (!selectedBrandDisplayName) {
            props.toast("error", "Please enter a brand display name to continue.");
        } else if (!cobrandingImageTemplate) {
            props.toast("error", "Please enter a cobranding image template to continue.");
        } else if (!primaryImageTemplate) {
            props.toast("error", "Please enter a primary image template to continue.");
        } else if (newClicked) {
            if (brands.find(brand => brand.id === selectedBrandId)) {
                props.toast("Error", "The brand ID you provided already is in use. Please enter a different brand ID.", "error");
            } else {
                TVEManagerDataProvider.createBrand(selectedBrandId, selectedProgrammerID, selectedBrandDisplayName, cobrandingImageTemplate, primaryImageTemplate, selectedBrandDefaultPlatforms).then(response => {
                    refreshBrands();
                    setNewClicked(false);
                    setOriginalBrandData({
                        id: selectedBrandId,
                        brand: selectedProgrammerID,
                        displayName: selectedBrandDisplayName,
                        primaryImgTemplate: primaryImageTemplate,
                        cobrandingImgTemplate: cobrandingImageTemplate,
                        defaultPlatforms: selectedBrandDefaultPlatforms
                    });
                    props.toast("success", "Brand created");
                }).catch(error => {
                    props.toast("error", "Error creating brand");
                });
            }
        } else {
            if (originalBrandData && (originalBrandData.id !== selectedBrandId || originalBrandData.brand !== selectedProgrammerID ||
                originalBrandData.displayName !== selectedBrandDisplayName || originalBrandData.cobrandingImgTemplate !== cobrandingImageTemplate ||
                originalBrandData.primaryImgTemplate !== primaryImageTemplate ||
                JSON.stringify(originalBrandData.defaultPlatforms.sort()) !== JSON.stringify(selectedBrandDefaultPlatforms.sort()))) {
                TVEManagerDataProvider.saveBrand(selectedBrandId, selectedProgrammerID, selectedBrandDisplayName, cobrandingImageTemplate, primaryImageTemplate, selectedBrandDefaultPlatforms).then(response => {
                    console.log("(BrandEntityEditorTab.saveBrand) saveBrand response: ", response);
                    setOriginalBrandData({
                        id: selectedBrandId,
                        brand: selectedProgrammerID,
                        displayName: selectedBrandDisplayName,
                        primaryImgTemplate: primaryImageTemplate,
                        cobrandingImgTemplate: cobrandingImageTemplate,
                        defaultPlatforms: selectedBrandDefaultPlatforms
                    });
                    refreshBrands();
                    props.toast("success", "Brand saved");
                }).catch(error => {
                    props.toast("error", "Error saving brand");
                    console.error("PlatformEntityEditorTab.saveBrand error: ", error);
                });
            }
        }
    };

    const overrideProviderPlatformsClicked = () => {
        const defaultPlatforms = [];
        console.log(platforms, selectedBrandDefaultPlatforms);
        for (const platform of platforms) {
            if (selectedBrandDefaultPlatforms.includes(platform.id)) {
                defaultPlatforms.push(platform);
            }
        }

        TVEManagerDataProvider.overrideProviderPlatforms(selectedBrandId, defaultPlatforms).then(response => {
            props.toast("Success", `Default platforms have successfully been overridden for the brand ${selectedBrandId}`, "success");
            console.log("(BrandEntityEditorTab.overrideProviderPlatformsClicked) response: ", response);
        }).catch(error => {
            props.toast("Error", "Error overriding default platforms.", "error");
            console.error(error);
        });
    };

    return (
        <Grid>
            <Grid.Column width={8} className="masterContainer">
                <Grid>
                    <Grid.Column floated="left" width={8}>
                        <Header as="h1">Brand Editor</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8}>
                        <Container fluid textAlign="right">
                            <Button.Group>
                                <ContingentButton
                                    onClick={onClickNew}
                                    primary
                                    service={props.service}
                                    module={props.module}
                                    scope={["all", "admin"]}
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >New Brand</ContingentButton>
                                <Button.Or text=""/>
                                <Button onClick={refreshBrands} secondary>Refresh</Button>
                            </Button.Group>
                            <br /><br />
                        </Container>
                    </Grid.Column>
                </Grid>
                <ReactTable
                    data={brands}
                    columns={[
                        {accessor: "id", show: false},
                        {Header: "Brand ID", accessor: "id"},
                        {Header: "Display Name", accessor: "displayName"},
                        {accessor: "primaryImgTemplate", show: false},
                        {accessor: "cobrandingImgTemplate", show: false},
                        {accessor: "defaultPlatforms", show: false},
                        {Header: "Programmer ID", accessor: "brand"}
                    ]}
                    filterable
                    defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                    getTrProps={(state, rowInfo, column, instance) => {
                        return {
                            onClick(event, handleOriginal) {
                                setSelectedBrandId(rowInfo.row.id);
                                setSelectedBrandDisplayName(rowInfo.row.displayName);
                                setNewClicked(false);
                                setPrimaryImageTemplate(rowInfo.row.primaryImgTemplate);
                                setCobrandingImageTemplate(rowInfo.row.cobrandingImgTemplate);
                                setSelectedBrandDefaultPlatforms(rowInfo.row.defaultPlatforms);
                                setSelectedProgrammerID(rowInfo.row.brand);
                                setOriginalBrandData(rowInfo.original);
                            },
                            style: {
                                background: rowInfo && rowInfo.row && selectedBrandId === rowInfo.row.id ? "rgba(65, 83, 175, .5)" : ""
                            }
                        }
                    }}
                    SubComponent={row =>
                        <BrandTopNineSubComponentTab
                            brand={row.row.id}
                            TVEManagerDataProvider={TVEManagerDataProvider}
                            toast={props.toast}
                        />
                    }
                    className="-striped -highlight"
                    defaultPageSize={14}
                />
            </Grid.Column>
            <Grid.Column width={8} className="detailsContainer">
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Popup
                                content={<Message compact color="green">You have made unsaved changes to this brand.</Message>}
                                trigger={
                                <ContingentButton
                                    onClick={saveBrand}
                                    primary
                                    disabled={!selectedBrandId || !unsavedChanges || (newClicked && (selectedBrandId.match(/\W/) || selectedProgrammerID.match(/\W/))) || !props.userCanEdit}
                                    service={props.service}
                                    module={props.module}
                                    scope={["all", "admin"]}
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >{newClicked ? "Create" : "Save"}</ContingentButton>}
                                open={unsavedChanges && props.userCanEdit}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <ContingentButton
                                onClick={overrideProviderPlatformsClicked}
                                disabled={!selectedBrandId || Object.keys(originalBrandData).length < 1 || !props.userCanEdit}
                                service={props.service}
                                module={props.module}
                                scope={["all", "admin"]}
                                user={props.user}
                                allPermissions={props.permissions}
                            >Override Provider Platforms</ContingentButton>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Popup
                            trigger={
                                <Form.Field
                                    label="Brand ID"
                                    control={Input}
                                    readOnly={!newClicked}
                                    value={selectedBrandId}
                                    icon={<Icon name={newClicked ? "unlock alternate" : "lock"} color={newClicked ? "black" : "red"} />}
                                    iconPosition="left"
                                    onChange={(event, {value}) => setSelectedBrandId(value)}
                                />
                            }
                            open={selectedBrandId.match(/[A-Z\W]/)}
                            style={{backgroundColor: "red"}}
                            inverted
                            content="The brand ID cannot contain spaces, special characters, or capital letters."
                        />
                        <Popup
                            trigger={
                                <Form.Field
                                    label="Programmer ID"
                                    control={Input}
                                    readOnly={!newClicked}
                                    value={selectedProgrammerID}
                                    icon={<Icon name={newClicked ? "unlock alternate" : "lock"} color={newClicked ? "black" : "red"} />}
                                    iconPosition="left"
                                    onChange={(event, {value}) => setSelectedProgrammerID(value)}
                                />
                            }
                            open={selectedProgrammerID.match(/\W/)}
                            style={{backgroundColor: "red"}}
                            inverted
                            content="The programmer ID cannot include spaces or special characters."
                        />

                        <Form.Field
                            label="Brand Display Name"
                            control={Input}
                            value={selectedBrandDisplayName}
                            onChange={(event, {value}) => setSelectedBrandDisplayName(value)}
                        />
                    </Form.Group>
                    <Form.Field
                        label="Primary Image Template"
                        control={Input}
                        value={primaryImageTemplate}
                        onChange={(event, {value}) => setPrimaryImageTemplate(value)}
                    />
                    <Form.Field
                        label="Cobranding Image Template"
                        control={Input}
                        value={cobrandingImageTemplate}
                        onChange={(event, {value}) => setCobrandingImageTemplate(value)}
                    />
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>Default Platforms</label>
                            <Segment>
                                <List
                                    divided
                                    relaxed
                                    items={selectedBrandDefaultPlatforms.map(platform => <ListItem key={`${platform}-currently-assigned`}><Icon name="minus" color="red"/>{platform}</ListItem>)}
                                    onItemClick={
                                        (event, data) => {
                                            if (props.userCanEdit) {
                                                setSelectedBrandDefaultPlatforms(selectedBrandDefaultPlatforms.filter(platform => platform !== data.children[1]));
                                            }
                                        }
                                    }
                                />
                            </Segment>
                        </Form.Field>
                        <Form.Field>
                            <label>Remaining Platforms</label>
                            <Segment>
                                <List
                                    divided
                                    relaxed
                                    items={remainingPlatforms.map(platform => <ListItem key={`${platform}-remaining`}><Icon name="plus" color="green"/>{platform}</ListItem>)}
                                    onItemClick={
                                        (event, data) => {
                                            if (props.userCanEdit) {
                                                setSelectedBrandDefaultPlatforms(selectedBrandDefaultPlatforms.concat(data.children[1]));
                                            }

                                        }
                                    }
                                />
                            </Segment>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Grid.Column>
        </Grid>
    );
};
