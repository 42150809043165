import React, {useState, useEffect} from "react";
import {Table} from "semantic-ui-react";
import PrimaryOrderForm from "./PrimaryOrderForm";

export default function ProviderPrimaryOrderTable(props) {
    const [brands, setBrands] = useState({});

    useEffect(function setBrandsAndPrimaryOrders() {
        const updatedBrands = {};
        for (const brand of props.brands) {
            updatedBrands[brand.id] = brand.primaryOrder || "";
        }
        setBrands(updatedBrands);
    }, [JSON.stringify(props.brands), JSON.stringify(props.addedBrands)]);

    useEffect(function arePrimaryOrdersUpdated() {
        props.checkPrimaryOrdersUpdated();
    }, [JSON.stringify(brands), JSON.stringify(props.brands), JSON.stringify(props.addedBrands)]);

    return (
        <Table compact="very" celled fixed striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1} />
                    <Table.HeaderCell textAlign="right">Brand</Table.HeaderCell>
                    <Table.HeaderCell>Primary Order</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    Object.keys(brands).map(brand =>
                        <PrimaryOrderForm
                            key={`${props.selectedProviderId}-${brand}-primary-order-form`}
                            brand={brand}
                            order={brands[brand]}
                            setOrder={props.updatePrimaryOrder}
                        />)
                }
                {
                    Object.keys(props.addedBrands).map(brand =>
                        <PrimaryOrderForm
                            key={`${props.selectedProviderId}-${brand}-primary-order-form`}
                            brand={brand}
                            isAdded={true}
                            order={props.addedBrands[brand]}
                            setOrder={props.updateAddedBrand}
                            removeAddedBrand={props.removeAddedBrand}
                        />
                    )
                }
            </Table.Body>
        </Table>
    );
};
